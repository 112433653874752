
/*
 * 
 *		<< SF FRMWRK >>
 *
 */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.columns, .row {
	lost-flex-container: row;
	width: 100%;
	clear:fix;
}

.col-1 {lost-column: 1/12}
.col-2 {lost-column: 2/12}
.col-3 {lost-column: 3/12}
.col-4 {lost-column: 4/12}
.col-5 {lost-column: 5/12}
.col-6 {lost-column: 6/12}
.col-7 {lost-column: 7/12}
.col-8 {lost-column: 8/12}
.col-9 {lost-column: 9/12}
.col-10 {lost-column: 10/12}
.col-11 {lost-column: 11/12}
.col-12 {lost-column: 12/12}

.waffle-12 {lost-waffle: 1/12;}
.waffle-10 {lost-waffle: 1/10;}
.waffle-9 {lost-waffle: 1/9;}
.waffle-8 {lost-waffle: 1/8;}
.waffle-7 {lost-waffle: 1/7;}
.waffle-6 {lost-waffle: 1/6;}
.waffle-5 {lost-waffle: 1/5;}
.waffle-4 {lost-waffle: 1/4;}
.waffle-3 {lost-waffle: 1/3;}
.waffle-2 {lost-waffle: 1/2;}


/* helpers */

.fluid {
	max-width: 100%;
	height: auto;
}

p, ul {
	margin-top: 0;
}

.float-right, .right {
	float: right;
}

.float-left, .left {
	float: left;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.inline {display: inline;}
.block {display: block;}
.inline-block {display: inline-block;}
.flex {display: flex;}


.items-center,
.align-items-center {
	align-items: center;
}

.items-start,
.align-items-start {
	align-items: flex-start;
}

.items-end,
.align-items-end {
	align-items: flex-end;
}

.content-center,
.justify-content-center {
	justify-content: center;
}

.content-start,
.justify-content-start {
	justify-content: flex-start;
}

.content-end,
.justify-content-end {
	justify-content: flex-end;
}

.content-between,
.justify-content-between {
	justify-content: space-between;
}

.content-around,
.justify-content-around {
	justify-content: space-around;
}

.content-evenly,
.justify-content-evenly {
	justify-content: space-evenly;
}

.m-0 {margin: 0;}
.m-1 {margin: 1rem;}
.m-2 {margin: 2rem;}
.m-3 {margin: 3rem;}
.m-4 {margin: 4rem;}
.m-5 {margin: 5rem;}

.p-0 {padding: 0;}
.p-1 {padding: 1rem;}
.p-2 {padding: 2rem;}
.p-3 {padding: 3rem;}
.p-4 {padding: 4rem;}
.p-5 {padding: 5rem;}

.my-0 {margin-top: 0; margin-bottom: 0;}
.my-1 {margin-top: 1rem; margin-bottom: 1rem;}
.my-2 {margin-top: 2rem; margin-bottom: 2rem;}
.my-3 {margin-top: 3rem; margin-bottom: 3rem;}
.my-4 {margin-top: 4rem; margin-bottom: 4rem;}
.my-5 {margin-top: 5rem; margin-bottom: 5rem;}

.py-0 {padding-top: 0; padding-bottom: 0;}
.py-1 {padding-top: 1rem; padding-bottom: 1rem;}
.py-2 {padding-top: 2rem; padding-bottom: 2rem;}
.py-3 {padding-top: 3rem; padding-bottom: 3rem;}
.py-4 {padding-top: 4rem; padding-bottom: 4rem;}
.py-5 {padding-top: 5rem; padding-bottom: 5rem;}

.mx-0 {margin-left: 0; margin-right: 0;}
.mx-1 {margin-left: 1rem; margin-right: 1rem;}
.mx-2 {margin-left: 2rem; margin-right: 2rem;}
.mx-3 {margin-left: 3rem; margin-right: 3rem;}
.mx-4 {margin-left: 4rem; margin-right: 4rem;}
.mx-5 {margin-left: 5rem; margin-right: 5rem;}

.px-0 {padding-left: 0; padding-right: 0;}
.px-1 {padding-left: 1rem; padding-right: 1rem;}
.px-2 {padding-left: 2rem; padding-right: 2rem;}
.px-3 {padding-left: 3rem; padding-right: 3rem;}
.px-4 {padding-left: 4rem; padding-right: 4rem;}
.px-5 {padding-left: 5rem; padding-right: 5rem;}

.mr-0 {margin-right: 0;}
.mr-1 {margin-right: 1rem;}
.mr-2 {margin-right: 2rem;}
.mr-3 {margin-right: 3rem;}
.mr-4 {margin-right: 4rem;}
.mr-5 {margin-right: 5rem;}

.ml-0 {margin-left: 0;}
.ml-1 {margin-left: 1rem;}
.ml-2 {margin-left: 2rem;}
.ml-3 {margin-left: 3rem;}
.ml-4 {margin-left: 4rem;}
.ml-5 {margin-left: 5rem;}

.pr-0 {padding-right: 0;}
.pr-1 {padding-right: 1rem;}
.pr-2 {padding-right: 2rem;}
.pr-3 {padding-right: 3rem;}
.pr-4 {padding-right: 4rem;}
.pr-5 {padding-right: 5rem;}

.pl-0 {padding-left: 0;}
.pl-1 {padding-left: 1rem;}
.pl-2 {padding-left: 2rem;}
.pl-3 {padding-left: 3rem;}
.pl-4 {padding-left: 4rem;}
.pl-5 {padding-left: 5rem;}


.mt-0 {margin-top: 0;}
.mt-1 {margin-top: 1rem;}
.mt-2 {margin-top: 2rem;}
.mt-3 {margin-top: 3rem;}
.mt-4 {margin-top: 4rem;}
.mt-5 {margin-top: 5rem;}

.mb-0 {margin-bottom: 0;}
.mb-1 {margin-bottom: 1rem;}
.mb-2 {margin-bottom: 2rem;}
.mb-3 {margin-bottom: 3rem;}
.mb-4 {margin-bottom: 4rem;}
.mb-5 {margin-bottom: 5rem;}


.pt-0 {padding-top: 0;}
.pt-1 {padding-top: 1rem;}
.pt-2 {padding-top: 2rem;}
.pt-3 {padding-top: 3rem;}
.pt-4 {padding-top: 4rem;}
.pt-5 {padding-top: 5rem;}

.pb-0 {padding-bottom: 0;}
.pb-1 {padding-bottom: 1rem;}
.pb-2 {padding-bottom: 2rem;}
.pb-3 {padding-bottom: 3rem;}
.pb-4 {padding-bottom: 4rem;}
.pb-5 {padding-bottom: 5rem;}



/* form */

.group.alerts {
	margin-bottom: 0;
}

.group {
	clear:fix;
	margin-bottom: 1rem;

	input,select, textarea {
		border:1px solid transparent;
		width: calc(99.9% - 1rem);
		padding: .5rem;
		font-size: .875rem;
		background: white;
		outline: none;
		transition: all .2s;

		&:focus {
			border-color: transparent;
			transform: scale(1.01);
			box-shadow: 0 5px 10px -2px rgba(0,0,0,.2);
		}
	}
}

.checkbox {
	position: relative;
	padding-left: 20px;

	input[type="checkbox"] {
		position: absolute;
		left:0; top: 0;
		width: auto;
	}
}

label {
	p {
		font-size: 0.8125rem;
		line-height: 1.3em;
	}
}

.rodo-hide {
	display: none;
}

.error {
	border-bottom-color: red !important;
}

.error-message {
	font-weight: bold;
	font-size: .7rem;
	color: rgb(214, 0, 0);
}

.alert {
	padding: 0.5rem 1rem;
	color: white;
	background: #333;
	margin-bottom: 1rem;
	border-radius: 4px;
	font-weight: bold;
	font-size: 1rem;
	display: none;
}

.alert-danger {
	background: rgb(214, 0, 0);
}

.alert-warning {
	background: rgb(241, 117, 0);
}

.alert-success {
	background: rgb(0, 94, 0);
}

button {
	cursor: pointer;
}

table {
	margin-bottom: 1rem;
	border-collapse: collapse;
}

table th {
	font-weight: bold;
}

table th,
table td {
	padding: .5rem 1rem;
}

table td {
	border-top: 1px solid rgba(0,0,0,.1);
}

.table {
	margin-bottom: 1rem;
	border-collapse: collapse;
}

.table th {
	color: white;
	background: $accent;
	font-size: 1.08rem;
}

.table th {
	padding: .8rem 1.5rem;
	text-align: left; 
	box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
}

.table td {
	padding: .5rem 1.5rem;
	text-align: left; 
}

.table td {
	border-top: 1px solid rgba(0,0,0,.1);
}

.table tr:nth-child(2n+1) {
	background: rgba(0,0,0,.05);
}


.fluid-video {
	position: relative;
	overflow: hidden;
}

.fluid-video::before {
    display: block;
    content: "";
	padding-top: 56.25%;
}

.fluid-video iframe {
	position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}