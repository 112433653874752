@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&subset=latin-ext');
@import url('https://unpkg.com/aos@next/dist/aos.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.4.1/css/swiper.min.css');
@import 'var.scss';
@import 'normalize.css';
@import 'frmwrk.scss';
@lost flexbox flex;




:root {
	font-size: 14px;
}

%font {
	font-family: 'Lato', sans-serif;
}

body {
	background: #E9EBEE;
	@extend %font;
	color: $text;
	font-size: 1rem;
	line-height: 1.5rem;
	padding-top: 235px;
	position: relative;
}

h1, h2, h3, h4, h5, h6 {
	color: $title;
	@extend %font;
	font-weight: bold;
}

.container {
	lost-center: 1170px;
}

nav {
	height: 208px;
	position: fixed;
	top:0; left:0;
	width: 100%;
	z-index: 999;

	.top {
		background: $nav-accent;
		height: 60px;
		margin-bottom: 30px;

		.container {height: 100%;}

		.btn {
			padding: 10px 25px;
		}
	}
}

.navbar__toggler {display: none;}

.navbar-nav {
	margin: 0;
	padding: 0;
	width: 100%;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: all .2s;
	transform-origin: top;

	li {
		float: left;
		margin-left: 2px;
		flex: 1;
		height: 110px;
	}

	li:first-child {margin-left: 0;}

	a {
		width: calc(100% - 20px);
		transition: all .2s;
		display: flex;
		height: calc(100% - 20px);
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		font-size: 12px;
		background: white;
		padding: 10px;
		border-radius: 4px;
		border-bottom: 5px solid $nav-accent;
		color: $accent;

		img {
			max-width: 90%;
			max-height: 42px;
			margin-bottom: 15px;
			transition: all .2s;
		}

		span {
			display: block;
			line-height: 1.1;
			text-align: center;
		}
	}

	.active>a,
	a.active,
	a:hover,
	a:focus {
		border-bottom-color: $pink;
		color: $pink;

		img {
			filter: hue-rotate(2rad) brightness(180%) contrast(2);
		}
	}
}

.logo {
	float: left;

	img {
		float: left;
		height: 51px;
		transition: all .2s;
	}
}

.szukaj {
	input,button {
		float: left;
		border:0;
		height: 35px;
		padding: 0 20px;
	}

	input {
		min-width: 250px;
		border-radius: 8px 0 0 8px;
		outline: none;
		transition: all .2s;

		&:focus {
			opacity: .9;
		}
	}

	button {
		background: $btn;
		border-radius: 0 8px 8px 0;
		color: white;
		font-size: 14px;
		letter-spacing: .5px;
		font-weight: 900;
		transition: all .2s;

		&:focus,
		&:hover {
			background: $btn-darken;
		}
	}
}

.how, .login {
	text-decoration: none;
	color: white;
	transition: all .2s;

	&:hover {
		color: $pink;
	}
}

.login {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	img {margin-right: 10px;}
	
}

.affix {
	.navbar-nav {
		transform: scaleY(0) translateY(-100%);
		opacity: 0;
	}
}

.btn {
	background: $btn;
	color: white;
	text-decoration: none;
	font-size: 14px;
	line-height: 1.3em;
	font-weight: 900;
	padding: 12px 25px;
	border-radius: 8px;
	transition: all .15s;
	display: inline-block;
	border:0;

	&:hover {
		background: $btn-lighten;
		transform: scale(1.03);
		box-shadow: 0 3px 6px -1px rgba(0,0,0,.2);
	}

	&:active, &:focus {
		background: $btn-darken;
		transform: scale(.98);
		box-shadow: 0 1px 3px rgba(0,0,0,.4);
	}

}


header .container {
	position: relative;
}

header {
	h1 {
		display: flex;
		max-width: 30%;
		text-align: right;
		align-items: flex-end;
		justify-content: center;
		flex-direction: column;
		margin: 0;
		height: 100%;
		position: absolute;
		right: 50px;
		z-index: 500;
		color:  white;
		font-weight: 300;
		line-height: 1;
		font-size: 48px;

		strong {
			font-size: 63px;
			font-weight: 900;
		}
	}

	.swiper-slide {
		overflow: hidden;
		border-radius: 4px;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top:0; left:0;
			z-index: 20;
			background: url(../img/slidemask1.png) 0 0 no-repeat;
			height: 100%;
			width: 633px;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top:0; right:0;
			z-index: 30;
			background: url(../img/slidemask2.png) right top no-repeat;
			height: 100%;
			width: 481px;
		}

		img {
			border-radius: 4px;
			max-width: 100%;
			z-index: 10;
			float: left;
		}
	}
}


#oferty {

	.row:first-of-type {
		padding: 30px 0;
	}
	h2 {
		color: $title;
		font-weight: 900;
		font-size: 32px;
		margin: 0;
	}
}

.filtr {
	select {
		float: left;
		border:0;
		border-radius: 4px 0 0 4px;
		padding: 5px 15px;
	}

	button {
		border-radius: 0 4px 4px 0;
		padding: 7px 15px;
		font-size: 12px;
	}
}

.oferta {
	background: white;
	float: left;
	display: flex;
	flex-direction: column;
	margin-right: 10px;
	margin-bottom: 10px;
	border-radius: 4px;
	max-width: calc(25% - (10px - 10px/4));
	flex-basis: calc(25% - (10px - 10px/4));

	.image {
		position: relative;
		float: left;

		.label {
			background: $pink;
			color: white;
			position: absolute;
			text-decoration: none;
			border-radius: 4px;
			line-height: 1;
			padding: 3px 10px;
			font-size: 11px;
			font-weight: 900;
			letter-spacing: .05;
			text-transform: uppercase;
			top: 5px;
			right: 5px;
			z-index: 20;
			transition: all .2s;

			&:hover {
				background: rgb(206, 51, 79);
			}
		}

		.photo {
			float: left;
			width: 100%;
			overflow: hidden;
			border-radius: 4px 4px 0 0;

			&:hover {
				img {
					transform: scale(1.2);
					opacity: .9;
				}
			}
		}

		.photo img {
			float: left;
			width: 100%;
			border-radius: 4px 4px 0 0;
			transition: all 1s;
		}
	}

	h4 {
		margin: 0;
		padding: 15px;
		display: block;

		a {
			text-decoration: none;
			color: $accent;
			font-size: 14px;
			font-weight: bold;
			transition: all .2s;

			&:hover {
				color:  $pink;
			}
		}
	}

	.cena {
		color: $accent;
		font-weight: 900;
		font-size: 18px;
		padding: 0 15px 15px 15px;
	}
}

.oferta:nth-child(4n) {
	margin-right: 0;
}


footer {
	background: $nav-accent;
	padding: 60px 0;
	margin-top: 60px;

	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	ul {
		margin: 30px 0 0;
		padding: 0;

		li {
			list-style: none;
			display: inline;
			margin: 0 10px;

			a {
				text-decoration: none;
				color: #fff;
				transition: all .2s;

				&:hover {
					color: $pink;

					img {
						transform: scale(1.2);
					}
				}
			}

			img {
				width: 30px;
				transition: all .2s;
			}
		}
	}
}


/* wizytówka */

.powrot {
	img {
		float: left;
		margin: 1px 15px 0 0;
	}
}

#navbar {transition: all .2s; transform-origin: top}

.wizytowka #navbar {overflow: hidden}

.wizytowka .affix {
	height: 60px;
}

.wizytowka {
	nav {
		background: #fff;

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between
		}
	}

	.logo-firmy {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		text-decoration: none;
		line-height: 1.3;

		> span {
			font-size: 18px;
			margin-left: 15px;
			padding: 20px 15px;
			border-left: 1px solid #e2e2e2;
			strong, span {display: block;}

			strong {
				font-weight: bold;
				color: $accent;
			}

			span {
				font-weight: 300;
				color: #474F70;
			}
		}
	}

	.navbar-nav {
		float: right;
		width: auto;
		display: block;

		li {
			height: auto;
			margin-left: 5px;

			a {
				width: auto;
				padding: 10px 15px;
				border-radius: 4px;
				border:0;
				font-size: 18px;
				font-weight: bold;
				color: $accent;

				&.active,
				&:focus,
				&:hover {
					color: white;
					background: $accent;
				}
			}
		}
	}

	.swiper-slide {
		&::after,
		&::before {
			display: none;
		}
	}

	article {
		background: #fff;
		border-radius: 8px;
		

		section {
			padding: 30px;

			h3:first-of-type {
				margin-top: 0;
			}

			h3 {
				font-size: 32px;
				font-weight: 300;

				strong, b {
					font-weight: 900;
				}
			}

			ul {
				margin: 0;
				padding: 0;

				li {
					list-style: none;
					position: relative;
					padding-left: 15px;
					line-height: 1.3;
					margin-bottom: 7px;

					&:before {
						position: absolute;
						top: 5px; left:0;
						content:"";
						display: block;
						border-radius: 50%;
						width: 6px;
						height: 6px;
						border: 1px solid $pink;
					}
				}
			}
		}
	}

	aside {
		h3 {
			font-weight: 900;
			font-size: 32px;
			margin-top: 0;
			margin-bottom: 20px;
			line-height: 1.1;
		}
	}

	.gallery {
		a {
			lost-waffle: 1/2 auto 10px;
			border-radius: 8px;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #000;

			img {
				float: left;
				width: 100%;
				float: left;
				position: relative;
				z-index: 10;
				transition: all .5s;
			}

			&:hover {

				img {
					transform: scale(1.3);
					opacity: .8;
				}
			}
		}
	}


	footer {
		.dane {
			color: white;

			h4 {
				font-weight: 900;
				color: white;
				margin-top: 0;
				font-size: 24px;
			}

			.col {margin-right: 60px;}

			a {
				color: white;
				text-decoration: none;
				transition: all .2s;

				&:hover {
					color: $pink;
				}
			}
		}

		.footer_logo {
			display: flex;
			flex-direction: column;
			text-decoration: none;
			color: white;
		}
	}
} // wizytowka

.btn-block {
	display: block;
	width: 100%;
	text-align: center;
}


/* podstrona */

.nav-accordion,
.nav-accordion--opened {
	margin: 0;
	padding: 0;

	> li > a {
		color: $text;
		@extend %font;
		font-weight: bold;
		font-size: 20px;

		&:hover,
		&.active {
			background: #04043E;
			color: white;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		background: rgba(0,0,0,.02);

		a {
			padding: 7px 12px 7px 24px;
		}
	}

	li {
		list-style: none;
	}

	li a {
		color: $text;
		text-decoration: none;
		display: block;
		line-height: 1.3em;
		padding: 7px 12px;
		border-top: 1px solid rgba(0,0,0,.1);
		transition: all .2s;
	}

	>li:first-child > a {
		border-top: 0;
	}

	ul li a {
		&:hover,
		&.active {
			color: $accent;
			background: #f0f0f0;
		}
	}
}


.nav-accordion {
	ul:not(.show) {
		max-height: 0;
		overflow: hidden;
	}

	.show {max-height: 100%}
}

#gallery .col {
	lost-column: 1/3;
	margin-bottom: 30px;
}

#gallery a {
	float: left;
	width: 100%;
	overflow: hidden;
	border-radius: 8px;
	transition: all .2s;
	border:1px solid transparent;

	img {
		max-width: 100%;
		border-radius: 8px;
		float: left;
		transition: all .2s;
	}

	&:hover {
		background: transparent;
		border-color: $accent;

		img {
			transform: scale(.98);
			opacity: .9;
		}
	}
}

.gallery a::before {
	content: '';
	width: 30px;
	height: 30px;
	align-self: center;
	position: absolute;
	z-index: 20;
	transition: all .2s;
	opacity: 0;
	background-size: contain;
	background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUyLjk2NiA1Mi45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUyLjk2NiA1Mi45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4Ij4KPGc+Cgk8cGF0aCBkPSJNMjguOTgzLDIwaC02di02YzAtMC41NTItMC40NDgtMS0xLTFzLTEsMC40NDgtMSwxdjZoLTZjLTAuNTUyLDAtMSwwLjQ0OC0xLDFzMC40NDgsMSwxLDFoNnY2YzAsMC41NTIsMC40NDgsMSwxLDEgICBzMS0wLjQ0OCwxLTF2LTZoNmMwLjU1MiwwLDEtMC40NDgsMS0xUzI5LjUzNSwyMCwyOC45ODMsMjB6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8cGF0aCBkPSJNNTEuNzA0LDUxLjI3M0wzNi44NDUsMzUuODJjMy43OS0zLjgwMSw2LjEzOC05LjA0MSw2LjEzOC0xNC44MmMwLTExLjU4LTkuNDItMjEtMjEtMjFzLTIxLDkuNDItMjEsMjFzOS40MiwyMSwyMSwyMSAgIGM1LjA4MywwLDkuNzQ4LTEuODE3LDEzLjM4NC00LjgzMmwxNC44OTUsMTUuNDkxYzAuMTk2LDAuMjA1LDAuNDU4LDAuMzA3LDAuNzIxLDAuMzA3YzAuMjUsMCwwLjQ5OS0wLjA5MywwLjY5My0wLjI3OSAgIEM1Mi4wNzQsNTIuMzA0LDUyLjA4Niw1MS42NzEsNTEuNzA0LDUxLjI3M3ogTTIuOTgzLDIxYzAtMTAuNDc3LDguNTIzLTE5LDE5LTE5czE5LDguNTIzLDE5LDE5cy04LjUyMywxOS0xOSwxOSAgIFMyLjk4MywzMS40NzcsMi45ODMsMjF6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

.gallery a:hover::before {opacity: 1;}


.downloads {
	padding: 0;
	margin: 0;

	li {
		list-style: none;

		a {
			padding: 10px 20px 10px 50px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-decoration: none;
			border-top: 1px solid rgba(0,0,0,.1);
			transition: all .2s;
			background: rgba(0,0,0,.02);

			color: $text;
			position: relative;
			line-height: 1.1em;

			small {
				font-size: 12px;
				color: rgba(0,0,0,.5);
			}

			&::before {
				content: '‣';
				display: block;
				position: absolute;
				top: auto;
				left:20px;
				font-size: 36px;
				color: $accent
			}

			em {
				font-style: normal;
				text-transform: uppercase;
				color: rgba(0,0,0,.3);
			}

			&:hover {
				background: rgba(0,0,0,.05);
			}
		}
	}

	li:nth-child(2n+1) > a {
		background: transparent;
		&:hover {
			background: rgba(0,0,0,.05);
		}
	}

	li:last-child > a {
		border-bottom: 1px solid rgba(0,0,0,.1);
	}
}


.downloads li a .info {
	display: flex;
	align-items: center;
}

.downloads li a .info::after {
	content: "POBIERZ PLIK";
	display: block;
	background: $btn;
	color: white;
	font-weight: normal;
	padding: 8px 15px;
	float: right;
	margin-left: 30px;
	border-radius: 4px;
	transition: all .3s;
}

.downloads li a:hover .info::after {
	transform: scale(1.03);
	background: $btn-lighten;
	box-shadow: 0 10px 15px -8px rgba(0, 0, 0, .3);
}



@import 'rwd.css';