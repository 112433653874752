/* rwd

RRRRRRRRRRRRRRRRR         WWWWWWWW                           WWWWWWWW     DDDDDDDDDDDDD
R::::::::::::::::R        W::::::W                           W::::::W     D::::::::::::DDD
R::::::RRRRRR:::::R       W::::::W                           W::::::W     D:::::::::::::::DD
RR:::::R     R:::::R      W::::::W                           W::::::W     DDD:::::DDDDD:::::D
 R:::::R     R:::::R       W:::::W           WWWWW           W:::::W        D:::::D    D:::::D
 R:::::R     R:::::R        W:::::W         W:::::W         W:::::W         D:::::D     D:::::D
 R:::::RRRRRR:::::R          W:::::W       W:::::::W       W:::::W          D:::::D     D:::::D
 R:::::::::::::RR             W:::::W     W:::::::::W     W:::::W           D:::::D     D:::::D
 R::::RRRRRR:::::R             W:::::W   W:::::W:::::W   W:::::W            D:::::D     D:::::D
 R:::::R     R:::::R            W:::::W W:::::W W:::::W W:::::W             D:::::D     D:::::D
 R:::::R     R:::::R             W:::::W:::::W   W:::::W:::::W              D:::::D     D:::::D
 R:::::R     R:::::R              W:::::::::W     W:::::::::W               D:::::D    D:::::D
RR:::::R     R:::::R              W:::::::W       W:::::::W              DDD:::::DDDDD:::::D
R::::::R     R:::::R               W:::::W         W:::::W               D:::::::::::::::DD
R::::::R     R:::::R                W:::W           W:::W                D::::::::::::DDD
RRRRRRRR     RRRRRRR                 WWW             WWW                 DDDDDDDDDDDDD

*/


/* height: 1050px */
@media screen and (max-width: 1680px) {

}


/* height: 900px */
@media screen and (max-width: 1600px) {

}


/* height: 864px */
@media screen and (max-width: 1536px) {

}



/* height: 900px */
@media screen and (max-width: 1440px) {

}


/* height: 768px */
@media screen and (max-width: 1366px) {

}



/* height: 768px */
@media screen and (max-width: 1360px) {

}



/* height: 1024 & 720px */
@media screen and (max-width: 1280px) {

}



/* height: 768px, 600px */
@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 1023px) {}

@media screen and (max-width: 800px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 640px) {}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 480px) {}

@media screen and (max-width: 435px) {}

@media screen and (max-width: 425px) {}

@media screen and (max-width: 414px) {}

@media screen and (max-width: 375px) {}

@media screen and (max-width: 360px) {}

@media screen and (max-width: 320px) {}


